import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Button } from 'wix-ui-tpa/Button';
import styles from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';

interface ControllerProps {
  greetingsText: string;
  contentWidth: number;
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

export default translate()(
  withExperiments<WidgetProps>(
    ({ t, experiments, greetingsText, contentWidth, ...rest }) => {
      return (
        <div
          {...styles('root', {}, rest)}
          data-hook="booking-service-page-wrapper"
        >
          <div
            data-hook="booking-service-page-header"
            className={styles.header}
          >
            Hello I'm The Header
          </div>
          <div className={styles.dynamicWrapper}>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${contentWidth}%` }}
            >
              <TitleAndTagline />
            </div>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${100 - contentWidth}%` }}
            >
              Hello I'm The Sidebar
            </div>
          </div>
        </div>
      );
    },
  ),
);
