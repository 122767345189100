import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './TitleAndTagline.st.css';

interface ControllerProps {}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

export default translate()(
  withExperiments<WidgetProps>(({ t, experiments, ...rest }) => {
    return (
      <div {...styles('root', {}, rest)} data-hook="title-and-tagline-wrapper">
        <Text
          data-hook="title-and-tagline-title"
          className={styles.title}
          typography={TYPOGRAPHY.largeTitle}
          tagName="h2"
        >
          it is Title my dudes
        </Text>
        <Text
          data-hook="title-and-tagline-tagline"
          className={styles.tagline}
          typography={TYPOGRAPHY.runningText}
        >
          it is Tagline my dudes
        </Text>
      </div>
    );
  }),
);
